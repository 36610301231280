import React from 'react'

import ResponseHandler from 'components/Blocks/Layout/ResponseHandler'
import SurveyDashboard from 'components/Survey/Dashboard/SurveyDashboard'
import { useCurrentUserQuery, useSurveysSurveysQuery } from 'generated/graphql'

const SurveyDashboardContainer = () => {
  const currentUserResult = useCurrentUserQuery()

  const result = useSurveysSurveysQuery()

  return (
    <ResponseHandler {...currentUserResult}>
      {({ currentUser }) => {
        return (
          <ResponseHandler {...result}>
            {({ surveys }) => {
              if (!surveys) return <></>
              return (
                <SurveyDashboard
                  surveyTypesAllowedToAdd={currentUser.surveyTypesAllowedToAdd}
                  surveys={surveys.edges.map(e => e?.node)}
                  favoriteSurveyIds={currentUser.favoriteSurveyIds}
                />
              )
            }}
          </ResponseHandler>
        )
      }}
    </ResponseHandler>
  )
}

export default SurveyDashboardContainer
