import React from 'react'

import { makeStyles, FormControlLabel, Switch, Typography } from '@material-ui/core'

const useStyles = makeStyles(({ spacing }) => ({
  subtitle: {
    marginTop: spacing(),
    marginBottom: spacing(4),
    width: '70%',
  },
}))

type Props = {
  sftpNotification: boolean
  setSftpNotification: (value: boolean) => void
}

const UserNotificationsSftpUploads: React.FC<Props> = ({
  sftpNotification,
  setSftpNotification,
}) => {
  const classes = useStyles()

  return (
    <div>
      <Typography variant="h4">SFTP Upload Notifications</Typography>
      <FormControlLabel
        control={
          <Switch
            name="SftpNotifications"
            checked={sftpNotification}
            onChange={() => setSftpNotification(!sftpNotification)}
          />
        }
        label="Enable SFTP Upload Notifications"
      />
      <Typography color="textSecondary" className={classes.subtitle}>
        If this setting is enabled, you will received email notifications when an SFTP file is
        processed.
      </Typography>
    </div>
  )
}

export default UserNotificationsSftpUploads
