import React, { useContext } from 'react'

import { useApolloClient } from '@apollo/client'
import orderBy from 'lodash/orderBy'
import { Redirect, matchPath, useLocation } from 'react-router-dom'

import ResponseHandler from 'components/Blocks/Layout/ResponseHandler'
import Loader from 'components/Blocks/Loader'
import InsightsContainer from 'components/Insights/InsightsContainer'
import { insightsModuleEnabledInSolution } from 'components/Insights/insightsTabs'
import NoClosedSurveys, { PageEnum } from 'components/Insights/NoClosedSurveys'
import { InsightsFilter, StoreContext } from 'config/LocalStorage'
import {
  useSurveysSurveysQuery,
  useInsightsSurveyQuery,
  InsightsModulesEnum,
  SurveyProductTypeEnum,
  SurveyStatusEnum,
  CurrentUserDocument,
  CurrentUserQuery,
  BenchmarkNode,
} from 'generated/graphql'
import withErrorHandler from 'HOC/withErrorHandler'
import {
  CUSTOM_SURVEY_TYPES,
  EMPLOYEE_INSIGHTS,
  ORDER_TYPES,
  RESIDENT_INSIGHTS,
} from 'utils/constants'
import { defaultInsightsPage, getInsightsFieldsForProductType } from 'utils/insightsUtils'
import { SurveyNode } from 'utils/types'

type ISCProps = {
  selectedSurveyUuid: string
  availableSurveys: NonNullable<Array<SurveyNode>>
  insightsFilters: Array<InsightsFilter>
  availableInsightsModules: InsightsModulesEnum[]
  insightsBenchmark: BenchmarkNode | null
}

const InsightsSurveyContainer: React.FC<ISCProps> = props => {
  const result = useInsightsSurveyQuery({
    variables: {
      surveyUuid: props.selectedSurveyUuid,
    },
  })
  return (
    <ResponseHandler {...result}>
      {({ survey }) => {
        return <InsightsContainer {...props} survey={survey} />
      }}
    </ResponseHandler>
  )
}

const InsightsRoutesContainer = () => {
  const client = useApolloClient()
  const { currentUser } = client.readQuery({ query: CurrentUserDocument }) as NonNullable<
    CurrentUserQuery
  >
  const { pathname } = useLocation()
  const { store } = useContext(StoreContext)

  let surveyProductType = SurveyProductTypeEnum.EMPLOYEE

  if (pathname.startsWith(EMPLOYEE_INSIGHTS)) {
    surveyProductType = SurveyProductTypeEnum.EMPLOYEE
  } else if (pathname.startsWith(RESIDENT_INSIGHTS)) {
    surveyProductType = SurveyProductTypeEnum.RESIDENT
  } else if (currentUser.organization.solution) {
    surveyProductType = SurveyProductTypeEnum.EMPLOYEE
  } else if (currentUser.organization.residentSolution) {
    surveyProductType = SurveyProductTypeEnum.RESIDENT
  }

  const { lastInsightsPathname, insightsBenchmark, insightsFilters } = {
    [SurveyProductTypeEnum.EMPLOYEE]: {
      lastInsightsPathname: store.lastInsightsPathname,
      insightsBenchmark: store.employeeInsightsBenchmark,
      insightsFilters: store.insightsFilters,
    },
    [SurveyProductTypeEnum.RESIDENT]: {
      lastInsightsPathname: store.lastResidentInsightsPathname,
      insightsBenchmark: store.residentInsightsBenchmark,
      insightsFilters: store.residentInsightsFilters,
    },
  }[surveyProductType]

  const result = useSurveysSurveysQuery({
    variables: { surveyProductType },
  })
  return (
    <ResponseHandler {...result}>
      {({ surveys: surveyNodes }, { loading }) => {
        // Because of https://github.com/apollographql/react-apollo/issues/2202
        // we can render stale data when the product type is switched
        // (e.g. showing employee surveys when productType is "RESIDENT")
        // This causes the child to store persisted settings to the wrong survey product type.
        // To prevent this issue, render a loader when we are switching product types instead of stale data.
        if (loading) {
          return <Loader />
        }
        let surveys = surveyNodes?.edges
          .map(e => e?.node)
          // Cast surveys as NonNull because Graphene Relay type issues prevent us from generating it
          .filter((survey): survey is SurveyNode => survey !== null)
          .filter(
            survey =>
              survey.status === SurveyStatusEnum.CLOSED ||
              (survey.status === SurveyStatusEnum.LIVE && !survey.endDate),
          )
        if (!surveys?.length) {
          return <NoClosedSurveys page={PageEnum.INSIGHTS} />
        }

        const {
          solution,
          detailsPath,
          insightsModules,
          insightsUrls,
        } = getInsightsFieldsForProductType(currentUser, surveyProductType)

        const availableInsightsModules = insightsModules.filter(insightsModule =>
          insightsModuleEnabledInSolution(insightsModule, solution),
        )

        if (!availableInsightsModules.length) {
          return <div>You don't have access to any Insights Modules.</div>
        }
        if (!currentUser.filters?.length) {
          return <div>You don't have access to any filters.</div>
        }
        if (!availableInsightsModules.includes(InsightsModulesEnum.SNAPSHOTS)) {
          // Custom surveys only show a snapshot tab.
          // Omit them if the user does not have permission to view that tab.
          surveys = surveys.filter(s => !CUSTOM_SURVEY_TYPES.includes(s.type))
        }
        // If we need to redirect, use last visited url or default to first survey snapshot.
        const defaultSurvey = orderBy(surveys, ['endDate'], [ORDER_TYPES.DESCENDING])[0]
        const redirectUrl =
          lastInsightsPathname ||
          defaultInsightsPage(defaultSurvey.uuid, availableInsightsModules, surveyProductType)
        if (
          matchPath(pathname, {
            path: insightsUrls.DASHBOARD,
            exact: true,
          })
        ) {
          return <Redirect to={redirectUrl} />
        }
        const match = matchPath<{ uuid: string }>(pathname, { path: detailsPath })
        const selectedSurvey = surveys.find(s => s.uuid === match?.params?.uuid)
        if (!selectedSurvey) {
          return <Redirect to={redirectUrl} />
        }
        return (
          <InsightsSurveyContainer
            selectedSurveyUuid={selectedSurvey.uuid}
            availableSurveys={surveys}
            insightsFilters={insightsFilters}
            insightsBenchmark={insightsBenchmark}
            availableInsightsModules={availableInsightsModules}
          />
        )
      }}
    </ResponseHandler>
  )
}

export default withErrorHandler(InsightsRoutesContainer)
